<template>
    <div class="CarDriverAppointStaffView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-row v-for="row in Rows" :key="row" class="insert">
            <div v-for="col in 3" :key="col">
                <van-col :span="8" v-if="col_show(row,col)">
                    <van-tag plain :type="tag_type(row,col)" size="large" @click="dianji_tag(row,col)">{{ col_data(row,col) }}</van-tag>
                </van-col>
            </div>
        </van-row>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.id+v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.car_number}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待分配</van-tag>
                        <van-tag plain type="primary" v-else-if="v.state===1">已分配</van-tag>
                        <van-tag plain type="warning" v-else-if="v.state===2">装卸中</van-tag>
                        <van-tag plain type="success" v-else-if="v.state===3">已完成</van-tag>
                        <van-tag plain type="danger" v-else-if="v.state===4">已取消</van-tag>
                        <van-tag plain type="danger" v-else-if="v.state===5">已过期</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="排队号" :value="v.list_num" :border="false" />
                <van-cell class="note_cell" title="装卸平台" :value="v.with_terrace_name" :border="false" />
                <van-cell class="note_cell" title="业务类型" :value="v.car_business_type" :border="false" />
                <van-cell class="note_cell" title="集装箱号" :value="v.box_num" :border="false" />
                <van-cell class="note_cell" title="司机姓名" :value="v.car_driver_name" :border="false" />
                <van-cell class="note_cell" title="司机手机" :value="v.car_driver_phone" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { terrace_list_request } from '@/network/list'
import { car_driver_appoint_staff_index_request } from '@/network/WareHouse/CarDriverAppointStaff'

export default {
    name:'CarDriverAppointStaffView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: ''
                },
            },
            my_nav_bar_name: '装卸排队',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            form_key: Date.now(),
            cond: {
                with_terrace_id: '',
                page: 1
            },
            all_terrace: []
        }
    },
    computed:{
        Rows() {
            return Math.ceil(this.all_terrace.length/3)
        }
    },
    methods:{
        get_all_terrace() {
            this.$store.commit('true_loading')
            terrace_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_terrace = s.result
                        this.all_terrace.unshift({
                            id: '',
                            name: '全 部'
                        })
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        search() {
            this.cond.page = 1
            this.get_car_driver_appoint_staff()
        },
        reset() {
            this.cond = {
                with_terrace_id: '',
                page: 1
            }
            this.form_key =  Date.now()
        },
        onLoad() {
            setTimeout(() => {
                this.cond.page++
                car_driver_appoint_staff_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(s.msg)
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
        get_car_driver_appoint_staff() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            car_driver_appoint_staff_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(s.msg)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        show_details(v) {
            if(this.$_has('在线登记详情')) {
                this.$router.push({
                    name: 'CarDriverAppointStaffDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        col_show(row,col) {
            return (row-1)*3 + col -1 < this.all_terrace.length
        },
        col_data(row,col) {
            const index = (row-1)*3 + col -1
            return this.all_terrace[index].name
        },
        tag_type(row,col) {
            const index = (row-1)*3 + col -1
            let type = 'primary'
            if(this.all_terrace[index].id === this.cond.with_terrace_id) {
                type = 'danger'
            }
            return type
        },
        dianji_tag(row,col) {
            const index = (row-1)*3 + col -1
            this.cond.with_terrace_id = this.all_terrace[index].id
            this.search()
        },
        search() {
            this.cond.page = 1
            this.get_car_driver_appoint_staff()
            this.show_popup = false
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_terrace()
        this.get_car_driver_appoint_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.CarDriverAppointStaffView .van-cell-group {
    margin-bottom: 0.2rem;
}
.CarDriverAppointStaffView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.CarDriverAppointStaffView .note_cell {
    padding: 0.1rem 0.42667rem
}
.CarDriverAppointStaffView .search_title {
    text-align: center;
}
.CarDriverAppointStaffView .search_button {
    margin: 0.5rem;
}
.CarDriverAppointStaffView .insert {
    text-align: center;
    /* position: absolute; */
    transform: translateY(-40%);
}
</style>